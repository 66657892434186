/* Technologies Section Styles */
.tech-main-section {
  padding: 60px 20px;
  background-color: #102532;
  text-align: center;
  position: relative;
  z-index: 1;
  margin: 0;
}

.tech-main-heading {
  margin-bottom: 40px;
}

.tech-main-heading h2 {
  font-size: 42px;
  color: #ff6600;
  font-weight: 700;
  margin-bottom: 15px;
  letter-spacing: -0.02em;
}

.tech-main-subtitle {
  font-size: 16px;
  color: #8b95a1;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
  letter-spacing: -0.01em;
}

.tech-main-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 25px;
  max-width: 1400px;
  margin: 40px auto 0;
  padding: 0 20px;
}

.tech-main-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px 20px;
  background: rgba(16, 37, 50, 0.95);
  border-radius: 12px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  aspect-ratio: 1;
  position: relative;
  cursor: pointer;
}

.tech-main-item:hover {
  transform: translateY(-5px);
  background: rgba(22, 45, 61, 0.95);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.tech-main-icon-wrapper {
  width: 45px;
  height: 45px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.tech-main-icon-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.tech-main-item:hover .tech-main-icon-wrapper {
  transform: scale(1.1);
}

.tech-main-item:hover .tech-main-icon-wrapper img {
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
}

.tech-main-name {
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.tech-main-category {
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

/* Colores de categorías */
.tech-main-category {
  position: relative;
  padding: 4px 8px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
}

[data-category="FRONTEND"] {
  color: #ff6600;
}

[data-category="BACKEND"] {
  color: #4CAF50;
}

[data-category="DATABASE"] {
  color: #2196F3;
}

[data-category="CMS"] {
  color: #9C27B0;
}

[data-category="E-COMMERCE"] {
  color: #FFC107;
}

.tech-main-description {
  font-size: 13px;
  color: #8b95a1;
  margin: 12px 0 0 0;
  line-height: 1.4;
}

/* Media Queries */
@media (max-width: 1200px) {
  .tech-main-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .tech-main-section {
    padding: 40px 15px;
  }

  .tech-main-heading {
    margin-bottom: 30px;
  }

  .tech-main-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin-top: 30px;
  }

  .tech-main-heading h2 {
    font-size: 36px;
  }

  .tech-main-item {
    padding: 20px 15px;
  }

  .tech-main-icon-wrapper {
    width: 35px;
    height: 35px;
    margin-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .tech-main-section {
    padding: 30px 15px;
  }

  .tech-main-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin-top: 20px;
  }

  .tech-main-item {
    padding: 15px 10px;
  }

  .tech-main-name {
    font-size: 14px;
  }

  .tech-main-category {
    font-size: 10px;
    padding: 3px 6px;
  }

  .tech-main-description {
    display: none;
  }
}