/* Contact.css */
.contact-page-renamed {
  background-color: #102532;
  color: white;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Hero Section */
.contact-hero-renamed {
  height: 60vh;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  text-align: center;
}

.hero-content-renamed {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 2;
  animation: fadeInUp 1s ease-out;
}

.hero-content-renamed h1 {
  font-size: 4rem;
  color: #ff6600;
  font-weight: 700;
  margin-bottom: 20px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

.hero-content-renamed p {
  font-size: 1.2rem;
  color: #ffffff;
  line-height: 1.6;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  max-width: 600px;
  margin: 0 auto;
}

/* Main Content */
.contact-main-renamed {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

/* Section Title Styles */
.section-title-renamed {
  color: #ff6600;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 15px;
}

.section-title-renamed::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: linear-gradient(to right, #ff6600, #ff4500);
  border-radius: 2px;
}

.section-description-renamed {
  color: #cbd5e1;
  font-size: 1.1rem;
  text-align: center;
  max-width: 600px;
  margin: 0 auto 40px;
  line-height: 1.6;
}

/* Contact Info Section */
.contact-info-renamed {
  margin: 60px auto;
}

.info-grid-renamed {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 40px;
}

.info-card-renamed {
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 35px;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
  color: white;
}

.info-card-renamed:hover {
  transform: translateY(-8px);
  border-color: rgba(255, 102, 0, 0.3);
  box-shadow: 0 8px 25px rgba(255, 102, 0, 0.15);
}

.info-icon-renamed {
  color: #ff6600;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.info-card-renamed h3 {
  color: #ff6600;
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.info-card-renamed p {
  color: #cbd5e1;
  font-size: 1rem;
  line-height: 1.6;
}

/* Form Section */
.contact-form-section-renamed {
  margin: 80px auto;
  position: relative;
}

.contact-form-section-renamed::before {
  content: '';
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 102, 0, 0.3),
    rgba(255, 102, 0, 0.5),
    rgba(255, 102, 0, 0.3),
    transparent
  );
}

.form-container-renamed {
  max-width: 700px;
  margin: 0 auto;
}

.contact-form-renamed {
  display: grid;
  gap: 25px;
}

.form-group-renamed {
  position: relative;
}

.contact-form-renamed input,
.contact-form-renamed textarea {
  width: 100%;
  padding: 15px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.contact-form-renamed textarea {
  height: 150px;
  resize: vertical;
}

.contact-form-renamed input:focus,
.contact-form-renamed textarea:focus {
  outline: none;
  border-color: rgba(255, 102, 0, 0.5);
  box-shadow: 0 0 10px rgba(255, 102, 0, 0.2);
}

.submit-button-renamed {
  background: linear-gradient(135deg, #ff6600, #ff4500);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 16px 32px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.submit-button-renamed:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(255, 102, 0, 0.25);
}

.submit-button-renamed:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Social Section */
.social-section-renamed {
  text-align: center;
  margin: 80px auto;
  position: relative;
}

.social-section-renamed::before {
  content: '';
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 102, 0, 0.3),
    rgba(255, 102, 0, 0.5),
    rgba(255, 102, 0, 0.3),
    transparent
  );
}

.social-links-renamed {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.social-link-renamed {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff6600;
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.social-link-renamed:hover {
  transform: translateY(-5px);
  border-color: rgba(255, 102, 0, 0.3);
  box-shadow: 0 8px 25px rgba(255, 102, 0, 0.15);
  color: #ff4500;
}

/* Loader */
.loader-overlay-renamed {
  position: fixed;
  inset: 0;
  background: rgba(16, 37, 50, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.loader-spinner-renamed {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 102, 0, 0.3);
  border-top-color: #ff6600;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .info-grid-renamed {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .hero-content-renamed h1 {
    font-size: 3rem;
  }

  .section-title-renamed {
    font-size: 2rem;
  }

  .section-description-renamed {
    font-size: 1rem;
    padding: 0 20px;
  }

  .info-grid-renamed {
    grid-template-columns: 1fr;
  }

  .submit-button-renamed {
    font-size: 1rem;
    padding: 14px 28px;
  }
}

@media (max-width: 480px) {
  .contact-hero-renamed {
    height: 50vh;
  }

  .hero-content-renamed h1 {
    font-size: 2.5rem;
  }

  .hero-content-renamed p {
    font-size: 1rem;
  }

  .info-card-renamed {
    padding: 25px;
  }

  .social-link-renamed {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
  }

  .submit-button-renamed {
    padding: 12px 24px;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .hero-content-renamed,
  .info-card-renamed,
  .social-link-renamed,
  .submit-button-renamed {
    animation: none;
    transition: none;
  }

  .loader-spinner-renamed {
    animation: none;
  }
}

/* Touch Device Optimizations */
@media (hover: none) {
  .info-card-renamed:hover,
  .social-link-renamed:hover,
  .submit-button-renamed:hover {
    transform: none;
  }
}