.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(20px);
}

.scroll-to-top.visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.scroll-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #102532;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.scroll-button:hover {
  background-color: #ff6600;
  transform: translateY(-3px);
  box-shadow: 0 6px 16px rgba(255, 102, 0, 0.25);
}

.scroll-button svg {
  transition: transform 0.3s ease;
}

.scroll-button:hover svg {
  transform: translateY(-2px);
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .scroll-to-top {
    bottom: 20px;
    right: 20px;
  }

  .scroll-button {
    width: 45px;
    height: 45px;
  }

  .scroll-button svg {
    width: 20px;
    height: 20px;
  }
}