.whatsapp-container {
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 1000;
}

.whatsapp-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  border-radius: 50%;
  color: white;
  box-shadow: 0 4px 12px rgba(37, 211, 102, 0.4);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
  position: relative;
}

.whatsapp-icon {
  font-size: 32px;
  transition: transform 0.3s ease;
}

/* Animación de pulso */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(37, 211, 102, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0);
  }
}

.whatsapp-button {
  animation: pulse 2s infinite;
}

/* Efectos hover */
.whatsapp-button:hover {
  background-color: #20b858;
  transform: translateY(-4px) scale(1.05);
  box-shadow: 0 8px 20px rgba(37, 211, 102, 0.5);
}

.whatsapp-button:hover .whatsapp-icon {
  transform: scale(1.1);
}

/* Tooltip */
.tooltip {
  position: absolute;
  left: calc(100% + 15px);
  top: 50%;
  transform: translateY(-50%);
  background: #102532;
  color: white;
  padding: 8px 15px;
  border-radius: 6px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.tooltip::before {
  content: '';
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent #102532 transparent transparent;
}

.tooltip.visible {
  opacity: 1;
  visibility: visible;
}

/* Responsive */
@media (max-width: 768px) {
  .whatsapp-container {
    bottom: 20px;
    left: 20px;
  }

  .whatsapp-button {
    width: 50px;
    height: 50px;
  }

  .whatsapp-icon {
    font-size: 28px;
  }

  .tooltip {
    display: none;
  }
}