.services-page {
  padding-top: 80px;
  background-color: #102532;
  min-height: 100vh;
  color: white;
  overflow-x: hidden;
  position: relative;
}

/* Hero Section */
.services-hero {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  margin-top: -80px;
  position: relative;
}

.hero-content {
  text-align: center;
  z-index: 2;
  padding: 0 20px;
  max-width: 800px;
}

.hero-content h1 {
  font-size: 3.5rem;
  color: #ff6600;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  line-height: 1.2;
}

.hero-content h1 span {
  display: block;
  margin: 0 auto;
  max-width: 800px;
}

.hero-content p {
  font-size: 1.2rem;
  color: #cbd5e1;
  line-height: 1.6;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
  margin-top: 25px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Services Section */
.services-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  align-items: start;
}

.service-item {
  background: rgba(16, 37, 50, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.3s ease;
  height: auto;
  min-height: 320px;
}

.service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.service-item.featured {
  border-color: rgba(255, 102, 0, 0.3);
  box-shadow: 0 0 20px rgba(255, 102, 0, 0.15);
}

.service-item h3 {
  color: #ff6600;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 12px 0;
}

.service-item p {
  color: #cbd5e1;
  font-size: 14px;
  line-height: 1.4;
  margin: 0 0 15px 0;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 0 0 15px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.features-list li {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #cbd5e1;
  font-size: 13px;
  line-height: 1.3;
}

.feature-icon {
  color: #ff6600;
  flex-shrink: 0;
  font-size: 12px;
}

.service-stats {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  margin: 10px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #cbd5e1;
  font-size: 13px;
}

.price {
  color: white;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin: 12px 0;
}

.details-button {
  background: #ff6600;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  margin-top: auto;
}

.details-button:hover {
  background: #ff4500;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 102, 0, 0.2);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

.modal-content {
  background: #102532;
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  border: 1px solid rgba(255, 102, 0, 0.15);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-header h2 {
  color: #ff6600;
  font-size: 28px;
  font-weight: 700;
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  color: #cbd5e1;
  font-size: 24px;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.close-button:hover {
  color: #ff6600;
  transform: rotate(90deg);
}

.plans-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  margin: 30px 0;
}

.plan-card {
  background: rgba(255, 255, 255, 0.03);
  padding: 30px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
}

.plan-card.recommended {
  border-color: #ff6600;
  box-shadow: 0 0 25px rgba(255, 102, 0, 0.15);
}

.recommended-badge {
  position: absolute;
  top: -12px;
  right: 20px;
  background: #ff6600;
  color: white;
  padding: 6px 15px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(255, 102, 0, 0.3);
}

.plan-card h3 {
  color: white;
  font-size: 20px;
  margin-bottom: 20px;
}

.plan-price {
  color: #ff6600;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin: 25px 0;
  text-shadow: 0 2px 8px rgba(255, 102, 0, 0.2);
}

.plan-features {
  list-style: none;
  padding: 0;
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.plan-features li {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #cbd5e1;
}

.price-note {
  text-align: center;
  color: #cbd5e1;
  font-size: 14px;
  opacity: 0.7;
  margin: 20px 0;
}

.contact-button {
  display: block;
  width: 100%;
  background: #ff6600;
  color: white;
  text-align: center;
  padding: 16px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact-button:hover {
  background: #ff4500;
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(255, 102, 0, 0.25);
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .services-content {
    grid-template-columns: repeat(2, 1fr);
    max-width: 800px;
  }

  .hero-content h1 {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .services-content {
    grid-template-columns: 1fr;
    padding: 20px;
  }
  
  .service-item {
    min-height: auto;
  }
  
  .plans-grid {
    grid-template-columns: 1fr;
  }

  .hero-content h1 {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .services-content {
    padding: 15px;
    gap: 15px;
  }
  
  .service-item {
    padding: 15px;
  }

  .service-stats {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }
  
  .modal-content {
    padding: 20px;
  }

  .hero-content h1 {
    font-size: 2rem;
  }
}