/* Navbar Styles */
.main-navbar {
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;
  height: 120px;
  background: transparent;
}

.main-navbar.transparent {
  background: transparent;
}

.main-navbar.solid {
  background: rgba(16, 37, 50, 0.95);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.main-navbar-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
}

.main-navbar-logo {
  display: flex;
  align-items: flex-start;
  z-index: 1001;
}

.main-navbar-logo img {
  height: 180px;
  width: auto;
  padding-top: 60px;
  transition: transform 0.3s ease;
}

/* Desktop Menu */
.main-navbar-menu.desktop {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-navbar-item:last-child {
  position: relative;
  top: -4px;
}

.main-navbar-item {
  list-style-type: none;
}

.main-navbar-item a {
  font-size: 1.1rem;
  font-weight: 500;
  color: #cbd5e1;
  text-decoration: none;
  transition: all 0.3s ease;
}

.main-navbar-item a:hover {
  color: #ff6600;
}

.navbar-cta-button {
  background-color: #ff6600;
  padding: 10px 32px; /* Aumentado el padding horizontal y vertical */
  border-radius: 4px;
  font-size: 0.8rem; /* Aumentado ligeramente el tamaño de fuente */
  font-weight: 600; /* Aumentado el peso de la fuente */
  color: white !important;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  text-decoration: none;
  border: none;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  height: auto;
  line-height: 1;
  margin-left: 10px; /* Agregado margen izquierdo para separarlo un poco */
}

.navbar-cta-button:hover {
  background-color: #ff4500;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(255, 102, 0, 0.2); /* Aumentada la sombra en hover */
}

/* Mobile elements hidden by default */
.main-menu-overlay,
.main-menu-icon,
.main-close-menu-container,
.main-close-menu,
.main-navbar-menu.mobile {
  display: none;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .main-navbar {
    height: 80px;
  }

  .main-navbar-logo img {
    height: 120px;
    padding-top: 20px;
  }

  /* Hide desktop menu */
  .main-navbar-menu.desktop {
    display: none;
  }

  /* Show mobile menu when open */
  .main-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #102532;
    z-index: 1000;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .main-menu-overlay.open {
    display: flex;
  }

  .main-navbar-menu.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 0;
  }

  .main-close-menu-container {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1001;
  }

  .main-close-menu {
    display: block;
    background: none;
    border: none;
    color: white;
    font-size: 40px;
    cursor: pointer;
    padding: 10px;
    line-height: 1;
  }

  .main-menu-icon {
    display: block;
    cursor: pointer;
    z-index: 999;
  }

  .main-menu-icon .bar {
    width: 25px;
    height: 3px;
    background-color: #cbd5e1;
    margin: 5px 0;
    transition: all 0.3s ease;
  }

  /* Mobile menu animations */
  .main-menu-icon.open .bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 6px);
  }

  .main-menu-icon.open .bar:nth-child(2) {
    opacity: 0;
  }

  .main-menu-icon.open .bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -6px);
  }

  /* Mobile items styles */
  .main-navbar-item {
    text-align: center;
  }

  .main-navbar-item a {
    color: #cbd5e1;
    font-size: 18px;
    padding: 10px;
  }

  .navbar-cta-button.mobile {
    background-color: #ff6600;
    padding: 12px 24px;
    font-size: 14px;
    height: auto;
    margin-top: 20px;
  }
}