.sercotec-banner {
  padding: 60px 20px;
  background: linear-gradient(135deg, #102532 0%, #1a3a4f 100%);
  position: relative;
  overflow: hidden;
}

.sercotec-banner__container {
  max-width: 1200px;
  margin: 0 auto;
  background: rgba(26, 58, 79, 0.95);
  border-radius: 24px;
  padding: 40px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 102, 0, 0.1);
  position: relative;
}

.sercotec-banner__grid {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 40px;
  align-items: start;
}

.sercotec-banner__logo-section {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 30px;
  text-align: center;
}

.sercotec-banner__logo {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.sercotec-banner__stats {
  display: grid;
  gap: 20px;
}

.stat-item {
  background: rgba(255, 255, 255, 0.03);
  padding: 15px;
  border-radius: 12px;
}

.stat-number {
  display: block;
  color: #ff6600;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
}

.stat-label {
  color: #cbd5e1;
  font-size: 14px;
}

.sercotec-banner__title {
  color: white;
  font-size: 36px;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 30px;
}

.sercotec-banner__title .subtitle {
  display: block;
  color: #ff6600;
  font-size: 24px;
  margin-top: 10px;
}

.sercotec-banner__amount-card {
  background: linear-gradient(135deg, rgba(255, 102, 0, 0.1) 0%, rgba(255, 102, 0, 0.2) 100%);
  border-radius: 16px;
  padding: 25px;
  margin-bottom: 40px;
  border: 1px solid rgba(255, 102, 0, 0.2);
}

.amount-label {
  color: #cbd5e1;
  font-size: 14px;
  margin-bottom: 10px;
}

.amount-value {
  color: #ff6600;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
}

.amount-note {
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.sercotec-banner__benefits {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.benefit-card {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  padding: 25px;
  transition: all 0.3s ease;
}

.benefit-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.benefit-icon {
  color: #ff6600;
  font-size: 30px;
  margin-bottom: 15px;
}

.benefit-card h3 {
  color: white;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.benefit-card p {
  color: #cbd5e1;
  font-size: 14px;
  margin-bottom: 15px;
  line-height: 1.4;
}

.benefit-features {
  list-style: none;
  padding: 0;
  margin: 0;
}

.benefit-features li {
  color: #cbd5e1;
  font-size: 14px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.benefit-features li svg {
  color: #ff6600;
  font-size: 12px;
}

.sercotec-banner__timeline {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.timeline-item {
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.sercotec-banner__cta {
  display: flex;
  gap: 16px;
}

.cta-button {
  flex: 1;
  padding: 16px 24px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.3s ease;
  text-decoration: none;
  cursor: pointer;
}

.cta-button.primary {
  background: #ff6600;
  color: white;
  border: none;
}

.cta-button.primary:hover {
  background: #ff7519;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 102, 0, 0.2);
}

.cta-button.secondary {
  background: rgba(203, 213, 225, 0.1);
  color: #cbd5e1;
  border: 1px solid rgba(203, 213, 225, 0.2);
}

.cta-button.secondary:hover {
  background: rgba(203, 213, 225, 0.15);
  color: white;
  border-color: rgba(203, 213, 225, 0.3);
  transform: translateY(-2px);
}

.cta-button.secondary svg {
  font-size: 18px;
}

@media (max-width: 992px) {
  .sercotec-banner__grid {
    grid-template-columns: 1fr;
  }
  
  .sercotec-banner__benefits {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .sercotec-banner {
    padding: 40px 15px;
  }

  .sercotec-banner__container {
    padding: 30px 20px;
  }

  .sercotec-banner__title {
    font-size: 28px;
  }

  .sercotec-banner__title .subtitle {
    font-size: 20px;
  }

  .sercotec-banner__benefits {
    grid-template-columns: 1fr;
  }

  .sercotec-banner__cta {
    flex-direction: column;
  }

  .cta-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .sercotec-banner__container {
    padding: 20px 15px;
  }
  
  .stat-number {
    font-size: 20px;
  }
  
  .amount-value {
    font-size: 28px;
  }
}