.skeleton {
  background-color: rgba(16, 37, 50, 0.95);
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  height: 100%;
  min-height: 220px;
}

  .skeleton.circular {
    border-radius: 50%;
  }
  
  .skeleton-shine {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.08),
      transparent
    );
    animation: shine 1.5s infinite;
  }
  
  @keyframes shine {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }