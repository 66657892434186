.trusted-by {
  padding: 80px 20px;
  background-color: #102532;
  position: relative;
}

.trusted-by::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 1px;
  background: linear-gradient(
      90deg,
      rgba(255, 102, 0, 0) 0%,
      rgba(255, 102, 0, 0.3) 50%,
      rgba(255, 102, 0, 0) 100%
  );
}

.trusted-by-container {
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
}

.trusted-by h2 {
  font-size: 42px;
  color: #ff6600;
  font-weight: 700;
  margin-bottom: 15px;
}

.trusted-by-subtitle {
  font-size: 16px;
  color: #6b7280;
  max-width: 600px;
  margin: 0 auto 50px;
  line-height: 1.5;
}

.logos-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin: 0 auto;
  max-width: 1200px;
}

.logo-wrapper {
  flex: 0 1 200px;
  text-decoration: none;
}

.logo-card {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 25px;
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.logo-card:hover {
  background: rgba(255, 255, 255, 0.06);
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.client-logo {
  max-width: 100%;
  height: auto;
  transition: all 0.3s ease;
  opacity: 0.8;
}

.logo-card:hover .client-logo {
  opacity: 1;
  transform: scale(1.05);
}

/* Estilos específicos para cada logo */
.corfo-logo {
  width: 120px;
  filter: none;
}

.mi-semilla-negra-logo {
  width: 140px;
  filter: brightness(0) invert(1);
}

.spendshield-logo {
  width: 140px;
  filter: brightness(0) invert(1);
}

.matrona-naty-logo {
  width: 100px;
  filter: none;
  border-radius: 50%;
}

.abogado-andrés-gonzález-logo {
  width: 140px;
  filter: brightness(0) invert(1);
}

/* Media Queries */
@media (max-width: 1200px) {
  .logos-grid {
      padding: 0 20px;
  }
  
  .logo-wrapper {
      flex: 0 1 180px;
  }
}

@media (max-width: 992px) {
  .logos-grid {
      flex-wrap: wrap;
      gap: 20px;
  }
}

@media (max-width: 768px) {
  .trusted-by {
      padding: 60px 15px;
  }

  .trusted-by h2 {
      font-size: 32px;
  }

  .logo-wrapper {
      flex: 0 1 calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .logo-wrapper {
      flex: 0 1 100%;
  }
}