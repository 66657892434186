/* Portfolio.css */

.portfolio-page-renamed {
  background-color: #102532;
  color: white;
  min-height: calc(100vh - 80px); /* Ajustamos el min-height para considerar el navbar */
  padding-bottom: 80px;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
}

/* Header & Hero Styles */
.portfolio-header-renamed {
  height: 60vh;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  width: 100%;
  padding-top: 80px;
}

.portfolio-header-renamed::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to bottom,
    rgba(16, 37, 50, 0.75), /* Reducido de 0.95 */
    rgba(16, 37, 50, 0.65)  /* Reducido de 0.85 */
  );
  z-index: 1;
}


.portfolio-hero-renamed {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 2;
  text-align: center;
  animation: fadeInUp 1s ease-out;
}

.portfolio-hero-renamed h1 {
  font-size: 4rem;
  color: #ff6600;
  font-weight: 700;
  margin-bottom: 20px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5); /* Sombra más pronunciada */
  line-height: 1.2;
  position: relative;
}

.portfolio-hero-renamed p {
  font-size: 1.2rem;
  color: #ffffff; /* Cambiado a blanco puro para mejor contraste */
  line-height: 1.6;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); /* Sombra más pronunciada */
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

/* Introduction Section */
.portfolio-intro-renamed {
  max-width: 800px;
  margin: 60px auto;
  text-align: center;
  padding: 0 20px;
}

.portfolio-intro-renamed h1 {
  color: #ff6600;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 25px;
  line-height: 1.2;
}

.portfolio-intro-renamed p {
  color: #cbd5e1;
  font-size: 1.1rem;
  line-height: 1.7;
  opacity: 0.9;
}

/* Projects Grid */
.projects-grid-renamed {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

/* Project Cards */
.project-item-renamed {
  background: rgba(16, 37, 50, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  height: 100%;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(10px);
}

.project-item-renamed:hover {
  transform: translateY(-8px);
  border-color: rgba(255, 102, 0, 0.3);
  box-shadow: 0 8px 25px rgba(255, 102, 0, 0.15);
}

.project-item-renamed::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(45deg, rgba(255, 102, 0, 0.05), transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.project-item-renamed:hover::before {
  opacity: 1;
}

.project-item-renamed img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-item-renamed:hover img {
  transform: scale(1.05);
}

.project-item-renamed h3 {
  color: #ff6600;
  font-size: 1.3rem;
  font-weight: 600;
  margin: 20px 20px 10px;
  line-height: 1.4;
  z-index: 1;
  position: relative;
}

.project-item-renamed p {
  color: #cbd5e1;
  font-size: 0.95rem;
  line-height: 1.6;
  margin: 0 20px 20px;
  flex-grow: 1;
  opacity: 0.9;
  z-index: 1;
  position: relative;
}

/* Link Overlay */
.link-overlay-renamed {
  position: absolute;
  inset: 0;
  background: rgba(16, 37, 50, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 10;
}

.project-item-renamed:hover .link-overlay-renamed {
  opacity: 1;
}

.link-overlay-renamed a {
  background: linear-gradient(135deg, #ff6600, #ff4500);
  color: white;
  text-decoration: none;
  padding: 14px 28px;
  border-radius: 8px;
  font-weight: 500;
  transform: translateY(20px);
  transition: all 0.3s ease;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0;
}

.project-item-renamed:hover .link-overlay-renamed a {
  transform: translateY(0);
  opacity: 1;
}

.link-overlay-renamed a:hover {
  background: linear-gradient(135deg, #ff4500, #ff6600);
  transform: translateY(-2px);
}

.link-overlay-renamed a::after {
  content: '→';
  font-size: 1.2em;
  transition: transform 0.3s ease;
}

.link-overlay-renamed a:hover::after {
  transform: translateX(4px);
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .projects-grid-renamed {
    grid-template-columns: repeat(2, 1fr);
    max-width: 800px;
    gap: 25px;
  }

  .project-item-renamed img {
    height: 250px;
  }
}

@media (max-width: 768px) {
  .portfolio-header-renamed {
    height: 50vh;
  }

  .portfolio-hero-renamed h1 {
    font-size: 3rem;
  }

  .portfolio-intro-renamed h1 {
    font-size: 2rem;
  }

  .projects-grid-renamed {
    grid-template-columns: 1fr;
    max-width: 500px;
    gap: 30px;
  }

  .project-item-renamed img {
    height: 280px;
  }
}

@media (max-width: 480px) {
  .portfolio-hero-renamed h1 {
    font-size: 2.5rem;
  }

  .portfolio-hero-renamed p {
    font-size: 1rem;
  }

  .portfolio-intro-renamed {
    margin: 40px auto;
  }

  .portfolio-intro-renamed h1 {
    font-size: 1.8rem;
  }

  .portfolio-intro-renamed p {
    font-size: 1rem;
  }

  .project-item-renamed {
    margin: 0;
  }

  .project-item-renamed img {
    height: 220px;
  }

  .project-item-renamed h3 {
    font-size: 1.2rem;
  }

  .link-overlay-renamed a {
    padding: 12px 24px;
    font-size: 0.9rem;
  }
}

/* Touch Device Optimizations */
@media (hover: none) {
  .project-item-renamed:hover {
    transform: none;
  }

  .link-overlay-renamed {
    opacity: 1;
    background: linear-gradient(to top, rgba(16, 37, 50, 0.95), transparent);
    align-items: flex-end;
    padding-bottom: 20px;
  }

  .link-overlay-renamed a {
    transform: none;
    opacity: 1;
    background: #ff6600;
  }

  .project-item-renamed:hover img {
    transform: none;
  }
}

/* Accessibility Improvements */
@media (prefers-reduced-motion: reduce) {
  .portfolio-hero-renamed,
  .project-item-renamed,
  .link-overlay-renamed,
  .link-overlay-renamed a {
    animation: none;
    transition: none;
  }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  .portfolio-page-renamed {
    background-color: #0a1721;
  }

  .project-item-renamed {
    background: rgba(255, 255, 255, 0.03);
  }
}