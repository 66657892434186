/* src/pages/Home/styles/Home.css */

/* Contenedor principal */
.home-container {
  min-height: 100vh;
  background-color: #102532;
  overflow: hidden;
  font-family: 'Arial', sans-serif;
}

/* Hero Section */
.hero {
  position: relative;
  min-height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #102532;
  margin: 0;
  padding: 0;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/heroes/hero.jpg');
  background-size: cover;
  background-position: center;
  filter: brightness(0.8);
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(16, 37, 50, 0.95),
    rgba(16, 37, 50, 0.8)
  );
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  width: 100%;
  padding: 0 2rem;
  margin-top: 4rem;
  text-align: center;
}

.hero-title {
  font-size: 4rem;
  font-weight: 700;
  color: white;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.hero-title span {
  color: #ff6600;
}

.hero-subtitle {
  font-size: 1.5rem;
  color: #cbd5e1;
  margin-bottom: 3rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.hero-buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}

.hero-cta-primary {
  background-color: #ff6600;
  color: white;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
}

.hero-cta-secondary {
  background-color: transparent;
  color: white;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-weight: 500;
  border: 2px solid white;
  text-decoration: none;
  transition: all 0.3s ease;
}

.hero-cta-primary:hover {
  background-color: #ff4500;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(255, 102, 0, 0.2);
}

.hero-cta-secondary:hover {
  background-color: white;
  color: #102532;
  transform: translateY(-2px);
}

/* Secciones */
section {
  position: relative;
  margin: 0;
  padding: 60px 0;
}

.home-section {
  width: 100%;
  border: none;
}

.home-section--light {
  background-color: #ffffff;
}

.home-section--dark {
  background-color: #102532;
}

/* Separadores sutiles entre secciones del mismo color */
.home-section--dark + .home-section--dark::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1200px;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.05) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.home-section--light + .home-section--light::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1200px;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(16, 37, 50, 0) 0%,
    rgba(16, 37, 50, 0.05) 50%,
    rgba(16, 37, 50, 0) 100%
  );
}

/* ParallaxSection */
.parallax-section {
  background-color: #102532;
  position: relative;
  margin: 0;
  padding: 0;
}

.parallax-section > section {
  margin: 0;
  padding: 60px 0;
}

/* Media Queries */
@media (max-width: 1200px) {
  section,
  .parallax-section > section {
    padding: 50px 0;
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 3rem;
  }

  .hero-subtitle {
    font-size: 1.25rem;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  section,
  .parallax-section > section {
    padding: 40px 0;
  }
}

@media (max-width: 480px) {
  .hero-content {
    padding: 0 1rem;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.1rem;
  }

  section,
  .parallax-section > section {
    padding: 30px 0;
  }

  .hero-cta-primary,
  .hero-cta-secondary {
    width: 100%;
    text-align: center;
  }
}