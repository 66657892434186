/* BlogPage.css */
.blog-page {
  background-color: #102532;
  min-height: 100vh;
}

.blog-hero {
  min-height: 60vh;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 20px 60px;
  position: relative;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  z-index: 2;
}

.hero-content h1 {
  font-size: 4rem;
  color: #ff6600;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.hero-content p {
  font-size: 1.2rem;
  color: #cbd5e1;
  margin-bottom: 2rem;
  line-height: 1.6;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.search-container {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}

.search-input {
  width: 100%;
  padding: 1rem 1rem 1rem 3rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.search-input:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.15);
  border-color: #ff6600;
}

.search-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #cbd5e1;
  width: 20px;
  height: 20px;
}

.no-results {
  text-align: center;
  margin: 40px 0;
  color: #666;
  font-size: 1.2rem;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .hero-content h1 {
    font-size: 3.5rem;
  }
}

@media (max-width: 768px) {
  .blog-hero {
    min-height: 50vh;
    padding: 100px 20px 40px;
  }

  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .search-input {
    padding: 0.75rem 0.75rem 0.75rem 2.5rem;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 0.9rem;
  }
}