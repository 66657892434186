.home-services {
  padding: 80px 20px;
  background-color: #ffffff;
}

.home-services__heading {
  text-align: center;
  margin-bottom: 60px;
}

.home-services__title {
  font-size: 42px;
  color: #ff6600;
  font-weight: 700;
  margin-bottom: 15px;
}

.home-services__subtitle {
  font-size: 16px;
  color: #6b7280;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.5;
}

.home-services__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Estilos base para las cards */
.home-services__item {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.home-services__item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

/* Estilos para card destacada de Shopify */
.home-services__item.featured {
  background: linear-gradient(145deg, #ffffff 0%, #f8faff 100%);
  border: 2px solid rgba(255, 102, 0, 0.2);
  position: relative;
  overflow: hidden;
}

.home-services__item.featured::before {
  content: 'Para Emprendedores';
  position: absolute;
  background: #ff6600;
  color: white;
  text-align: center;
  line-height: 20px;
  transform-origin: center;
  transform: rotate(45deg);
  width: 170px;
  top: 25px;
  right: -45px;
  padding: 4px 0;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.home-services__item.featured:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px rgba(255, 102, 0, 0.1);
  border-color: rgba(255, 102, 0, 0.3);
}

.home-services__content {
  flex: 1;
}

.home-services__icon-wrapper {
  width: 64px;
  height: 64px;
  background-color: rgba(255, 102, 0, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.home-services__item.featured .home-services__icon-wrapper {
  background: linear-gradient(135deg, rgba(255, 102, 0, 0.15) 0%, rgba(255, 102, 0, 0.3) 100%);
  transform: scale(1.1);
}

.home-services__icon {
  font-size: 24px;
  color: #ff6600;
}

.home-services__item-title {
  font-size: 24px;
  color: #1f2937;
  margin-bottom: 12px;
  font-weight: 600;
}

.home-services__item.featured .home-services__item-title {
  color: #ff6600;
}

.home-services__description {
  color: #6b7280;
  margin-bottom: 20px;
  line-height: 1.6;
}

.home-services__features {
  margin: 20px 0;
  padding: 20px 0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.home-services__feature {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  color: #4b5563;
}

.home-services__feature:last-child {
  margin-bottom: 0;
}

.home-services__check-icon {
  color: #ff6600;
  font-size: 16px;
  flex-shrink: 0;
}

.home-services__stats {
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #4b5563;
  display: grid;
  gap: 10px;
}

.home-services__item.featured .home-services__stats {
  background: rgba(255, 102, 0, 0.05);
  border: 1px solid rgba(255, 102, 0, 0.1);
}

.home-services__stat {
  display: flex;
  align-items: center;
  gap: 8px;
}

.home-services__stat-icon {
  color: #ff6600;
  font-size: 14px;
}

/* Estilos para la sección de precios */
.pricing-info {
  margin-top: 15px;
  padding: 15px;
  background: rgba(255, 102, 0, 0.05);
  border-radius: 8px;
  text-align: center;
  border: 1px solid rgba(255, 102, 0, 0.1);
}

.pricing-info .price {
  font-size: 24px;
  color: #ff6600;
  font-weight: 700;
  margin-bottom: 5px;
}

.pricing-info .price-description {
  font-size: 14px;
  color: #6b7280;
}

/* Estilos para el botón */
.home-services__button {
  background-color: #ff6600;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  display: block;
  margin-top: auto;
}

.home-services__button:hover {
  background-color: #ff4500;
  transform: translateY(-2px);
}

.home-services__item.featured .home-services__button {
  background: linear-gradient(90deg, #ff6600 0%, #ff8533 100%);
  box-shadow: 0 4px 12px rgba(255, 102, 0, 0.2);
}

.home-services__item.featured .home-services__button:hover {
  background: linear-gradient(90deg, #ff8533 0%, #ff6600 100%);
  box-shadow: 0 6px 15px rgba(255, 102, 0, 0.3);
}

/* Media Queries */
@media (max-width: 992px) {
  .home-services__grid {
    grid-template-columns: 1fr;
    gap: 20px;
    max-width: 600px;
  }

  .home-services__item.featured::before {
    font-size: 11px;
    width: 150px;
    top: 20px;
    right: -40px;
  }
}

@media (max-width: 768px) {
  .home-services {
    padding: 60px 20px;
  }

  .home-services__title {
    font-size: 32px;
  }

  .home-services__item {
    padding: 20px;
  }

  .home-services__icon-wrapper {
    width: 50px;
    height: 50px;
  }

  .home-services__icon {
    font-size: 20px;
  }

  .home-services__item-title {
    font-size: 20px;
  }

  .pricing-info .price {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .home-services {
    padding: 40px 15px;
  }

  .home-services__heading {
    margin-bottom: 40px;
  }

  .home-services__title {
    font-size: 28px;
  }

  .home-services__subtitle {
    font-size: 14px;
  }

  .home-services__stats {
    padding: 12px;
  }

  .home-services__button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .pricing-info {
    padding: 12px;
  }

  .pricing-info .price {
    font-size: 18px;
  }

  .pricing-info .price-description {
    font-size: 13px;
  }
}