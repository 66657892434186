.footer {
  background-color: #102532;
  padding: 60px 0 30px;
  position: relative;
  color: #cbd5e1;
}

.footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, 
    rgba(255,255,255,0) 0%, 
    rgba(255,102,0,0.3) 50%, 
    rgba(255,255,255,0) 100%
  );
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin-bottom: 40px;
}

.footer-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-title {
  color: #ff6600;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
  padding-bottom: 10px;
}

.footer-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 2px;
  background: #ff6600;
  border-radius: 2px;
}

.footer-description {
  line-height: 1.6;
  opacity: 0.9;
}

.footer-schedule {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
}

.footer-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer-list li {
  display: flex;
  align-items: center;
  gap: 10px;
  transition: transform 0.3s ease;
}

.footer-list li:hover {
  transform: translateX(5px);
  color: #ff6600;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer-contact a {
  color: #cbd5e1;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: color 0.3s ease;
}

.footer-contact a:hover {
  color: #ff6600;
}

.footer-icon {
  color: #ff6600;
  font-size: 1rem;
  width: 20px;
}

.footer-divider {
  height: 1px;
  background: linear-gradient(90deg, 
    rgba(255,255,255,0) 0%, 
    rgba(255,255,255,0.1) 50%, 
    rgba(255,255,255,0) 100%
  );
  margin: 30px 0;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.copyright {
  font-size: 0.9rem;
  margin: 0;
}

.social-links {
  display: flex;
  gap: 15px;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.05);
  color: #cbd5e1;
  font-size: 1rem;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.social-link:hover {
  background: #ff6600;
  color: white;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(255, 102, 0, 0.2);
}

/* Media queries */
@media (max-width: 992px) {
  .footer-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 40px 0 30px;
  }

  .footer-grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .footer-section {
    text-align: center;
    align-items: center;
  }

  .footer-title::after {
    left: 50%;
    transform: translateX(-50%);
  }

  .footer-bottom {
    flex-direction: column;
    text-align: center;
  }

  .footer-list li {
    justify-content: center;
  }

  .footer-contact {
    align-items: center;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 30px 0 20px;
  }

  .social-link {
    width: 34px;
    height: 34px;
    font-size: 0.9rem;
  }

  .copyright {
    font-size: 0.8rem;
  }
}