/* Last Projects Section */
.last-projects {
  padding: 60px 20px;
  background: #ffffff;
  margin: 0;
  position: relative;
}

/* Separador superior sutil */
.last-projects::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1200px;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(16, 37, 50, 0) 0%,
    rgba(16, 37, 50, 0.05) 50%,
    rgba(16, 37, 50, 0) 100%
  );
}

.last-projects-heading {
  margin-bottom: 40px;
  text-align: center;
}

.last-projects h2 {
  font-size: 42px;
  color: #ff6600;
  font-weight: 700;
  margin-bottom: 15px;
  letter-spacing: -0.02em;
}

.last-projects-subtitle {
  font-size: 16px;
  color: #6b7280;
  line-height: 1.5;
  max-width: 600px;
  margin: 15px auto 0;
  letter-spacing: -0.01em;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  max-width: 1200px;
  margin: 40px auto 0;
}

.project-item {
  position: relative;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  height: 350px;
  contain: layout style paint;
}

.project-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.project-image-container {
  width: 100%;
  height: 250px;
  overflow: hidden;
  position: relative;
  contain: strict;
  background: #f8f9fa;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.project-item:hover .project-image {
  transform: scale(1.05);
}

.project-info {
  padding: 20px;
  background: white;
  position: relative;
  z-index: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.project-item h3 {
  font-size: 16px;
  color: #102532;
  margin: 0;
  font-weight: 600;
  line-height: 1.4;
}

.link-overlay {
  position: absolute;
  inset: 0;
  background: rgba(16, 37, 50, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.project-item:hover .link-overlay {
  opacity: 1;
  visibility: visible;
}

.project-link {
  padding: 12px 24px;
  background: #ff6600;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  transform: translateY(20px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}

.project-item:hover .project-link {
  transform: translateY(0);
  opacity: 1;
}

.project-link:hover {
  background: #ff4500;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 102, 0, 0.2);
}

/* Media Queries */
@media (max-width: 1200px) {
  .projects-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0 20px;
  }
  
  .project-item {
    height: 330px;
  }
}

@media (max-width: 768px) {
  .last-projects {
    padding: 40px 15px;
  }

  .last-projects h2 {
    font-size: 36px;
  }

  .projects-grid {
    grid-template-columns: repeat(1, 1fr);
    max-width: 500px;
    margin-top: 30px;
  }

  .project-item {
    height: 320px;
  }

  .project-image-container {
    height: 220px;
  }
}

@media (max-width: 480px) {
  .last-projects {
    padding: 30px 15px;
  }

  .project-item {
    height: 300px;
  }

  .project-image-container {
    height: 200px;
  }

  .project-info {
    padding: 15px;
  }

  .project-item h3 {
    font-size: 15px;
  }

  .project-link {
    padding: 10px 20px;
    font-size: 13px;
  }
}