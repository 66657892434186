/* Base Styles */
.contact-section {
  background-color: #102532;
  padding: 0px 20px;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

/* Form Container */
.contact-form-container {
  background: rgba(16, 37, 50, 0.4);
  border-radius: 16px;
  padding: 40px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.contact-form-container h2 {
  color: #ff6600;
  font-size: 32px;
  margin-bottom: 10px;
  font-weight: 600;
}

.contact-subtitle {
  color: #cbd5e1;
  opacity: 0.7;
  margin-bottom: 30px;
  font-size: 15px;
  line-height: 1.5;
}

/* Form Elements */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  background: rgba(16, 37, 50, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 12px 16px;
  color: #cbd5e1;
  font-size: 15px;
  transition: all 0.3s ease;
}

.form-group textarea {
  min-height: 120px;
  resize: vertical;
}

.form-group-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.form-group-row input {
  width: 100%;
  background: rgba(16, 37, 50, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 12px 16px;
  color: #cbd5e1;
  font-size: 15px;
  transition: all 0.3s ease;
}

/* Focus States */
.form-group input:focus,
.form-group textarea:focus,
.form-group-row input:focus {
  background: rgba(16, 37, 50, 0.8);
  border-color: #ff6600;
  outline: none;
}

/* Placeholder */
.form-group input::placeholder,
.form-group textarea::placeholder,
.form-group-row input::placeholder {
  color: rgba(203, 213, 225, 0.4);
}

/* Submit Button */
.submit-button {
  background: #ff6600;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  transition: all 0.3s ease;
  margin-top: 10px;
}

.submit-button:hover {
  background: #ff4500;
  transform: translateY(-2px);
}

.whatsapp-icon {
  font-size: 20px;
}

/* Contact Info Container */
.contact-info-container {
  background: rgba(16, 37, 50, 0.4);
  border-radius: 16px;
  padding: 40px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.contact-info h3,
.documents-section h3 {
  color: #ff6600;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 600;
}

/* Contact Details */
.contact-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 40px;
}

.contact-detail {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #cbd5e1;
  text-decoration: none;
  padding: 12px 16px;
  border-radius: 8px;
  background: rgba(16, 37, 50, 0.6);
  transition: all 0.3s ease;
}

.contact-detail:hover {
  background: rgba(255, 255, 255, 0.05);
  color: #ff6600;
}

.contact-detail svg {
  color: #ff6600;
  font-size: 18px;
}

/* Documents Section */
.documents-section p {
  color: #cbd5e1;
  margin-bottom: 20px;
  line-height: 1.6;
}

.documents-grid {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.document-link {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #cbd5e1;
  text-decoration: none;
  padding: 12px 16px;
  border-radius: 8px;
  background: rgba(16, 37, 50, 0.6);
  transition: all 0.3s ease;
}

.document-link:hover {
  background: rgba(255, 102, 0, 0.1);
  transform: translateX(5px);
}

.document-link svg {
  color: #ff6600;
  font-size: 18px;
}

/* Brand Section */
.brand-section {
  margin-top: 40px;
  text-align: center;
  padding-top: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.brand-logo {
  width: 100px;
  margin-bottom: 20px;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-links a {
  color: #cbd5e1;
  font-size: 18px;
  padding: 8px;
  transition: all 0.3s ease;
}

.social-links a:hover {
  color: #ff6600;
  transform: translateY(-2px);
}

/* Media Queries */
@media (max-width: 1024px) {
  .contact-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .contact-section {
    padding: 40px 20px;
  }

  .form-group-row {
    grid-template-columns: 1fr;
  }

  .contact-form-container,
  .contact-info-container {
    padding: 30px;
  }
}

@media (max-width: 480px) {
  .contact-section {
    padding: 30px 15px;
  }

  .contact-form-container,
  .contact-info-container {
    padding: 20px;
  }

  .contact-form-container h2 {
    font-size: 28px;
  }

  .submit-button {
    padding: 14px;
  }
}